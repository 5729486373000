.offersSection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vmax;
  }

  .offersSection   img{
    margin: 0 1vmax;
    transition: all 0.5s;
}

.offersSection   img:hover{
    box-shadow: 6px 6px 15px rgba(53, 53, 53, 0.363);
  }