.footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
    padding: 4.5vmax;
    background-color: rgb(37, 37, 37);
    color: rgb(197, 197, 197);
}

.footer > div{
    margin:1vmax;
    display: flex;
    flex-direction: column;

}

.footer > div:nth-child(2){
    display: block;
}
.footer > div:nth-child(2) >a{
        display: inline-block;

}
.footer > div > a{
    color: rgb(197, 197, 197);
    font-size: 1vmax;
    text-decoration: none;
    margin: 0.6vmax 0;
}

.footer > div > a:hover{
    color: white;
}


.footer > div:last-child >div{
    width: 45%;
    margin: 1vmax 0;
    font-size: 0.8vmax;
}

.footer > div:last-child >button{
width: 50%;
border:1px solid white;
background-color: rgb(37, 37, 37);
color: white;
padding: 0.6vmax 0;
font-size: 0.98vmax;
font-weight: 100;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
transition: all 0.5s;
}

.footer > div:last-child >button:hover{
    background-color: rgb(248, 244, 244);
    color: rgb(37, 37, 37);
}

.footerBorder{
    background-color:   rgb(37, 37, 37)  ; 
    color: white;
    
}
.footerBorder >div{
    border-top: 1px solid rgba(197, 197, 197, 0.185) ;
    color: rgb(197, 197, 197);
    width: 90vmax;
    margin: auto;
    padding: 2vmax;
   font-size: 0.98vmax;
}